import { post } from '@/http'
// 获取退换货订单列表
export function getExOrderList(data) {
    return new Promise((resolve, reject) => {
        post('/ExOrder/GetExOrderList', data)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}
