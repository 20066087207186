<template>
	<div>
		<div class="top">
			<div class="title">退款 / 售后</div>
			<div class="policy">售后政策</div>
		</div>
		<div class="form">
			<el-form ref="form" :model="form" label-width="120px">
				<el-row>
					<el-col :span="8">
						<el-form-item label="售后类型：">
							<el-select v-model="parameter.Type" placeholder="请选择售后类型" style="width:230px" size="small" @change="search">
								<el-option label="退货退款" value="1"></el-option>
								<el-option label="仅退款" value="2"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="售后状态：">
							<el-select v-model="parameter.State" style="width:230px" size="small" @change="search">
								<el-option label="审核中" value="0"></el-option>
								<el-option label="审核通过" value="1"></el-option>
								<el-option label="审核失败" value="2"></el-option>
								<el-option label="买家已发货" value="3"></el-option>
								<el-option label="商家确认中" value="4"></el-option>
								<el-option label="退款成功" value="5"></el-option>
								<el-option label="已取消" value="9"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="申请时间：">
							<el-date-picker v-model="parameter.Time" type="date" @change="search" size="small" placeholder="请选择申请时间"></el-date-picker>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="8">
						<el-form-item label="订单号：">
							<el-input size="small" v-model="parameter.OrderID" placeholder="请输入订单号">
								<template slot="append">
									<span @click="search" style="color: #333333;">查询</span>
								</template>
							</el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="退款编号：">
							<el-input size="small" v-model="parameter.ExOrderID" placeholder="请输入退款/退款编号">
								<template slot="append">
									<span @click="search" style="color: #333333;">查询</span>
								</template>
							</el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</div>
		<div class="list">
			<div>
				<table border="0" cellspacing="0" cellpadding="0">
					<tr class="list-title">
						<th width="450">商品信息</th>
						<th width="100">数量</th>
						<th width="110">退款金额</th>
						<th width="100">申请时间</th>
						<th width="100">服务类型</th>
						<th width="110">售后状态</th>
						<th width="180">操作</th>
					</tr>
				</table>

				<!--订单号-->
				<table style="border-collapse:separate; border-spacing:0 10px;" border="0" cellspacing="0" cellpadding="0" v-for="(item, index) in cartList" :key="index">
					<tr class="order-mian" style="margin:10px">
						<td
							width="430"
							style="padding-left:10px;
                                        color: #999999;"
						>
							<span style="margin-left:5px">{{ item.ExOrder.CreatTime }}</span>
							<span style="margin-left:5px">退款编号</span>
							<span class="order-number">{{ item.ExOrder.ExOrderID }}</span>
						</td>
						<td width="110" v-for="j in 5" :key="j"></td>
						<td width="220" style="text-align: right;">
							<a href="javascript:;" title="客服" @click="toChat(item)" style="margin-right:10px;margin-top:5px;color: #666666;">
								<i slot="reference" class="iconfont iconshouhou"></i>
							</a>
						</td>
					</tr>
					<tr class="order-mian-list" v-for="(j, i) in item.DetailInfoList" :key="i">
						<td style="display: flex" class="border">
							<div><img :src="pic + j.ExOrderDetail.ProPictrue" style="cursor: pointer;" @click="handleTo(j)" alt="" /></div>
							<div class="order-mian-list-text">
								<div class="proName" style="cursor: pointer;" @click="handleTo(j)">{{ j.ExOrderDetail.ProName }}</div>
								<!-- <div class="sku">
                                    SKU:{{ j.OrderDetail.BarCode }}
                                </div> -->
							</div>
						</td>
						<td class="border">
							<div>X {{ j.ExOrderDetail.PCount }}</div>
						</td>
						<td class="border">
							<div>{{ j.ExOrderDetail.ExPrice }}</div>
						</td>
						<td class="border-left border-right">
							<div class="operation">
								<div>{{ item.ExOrder.CreatTime.substring(0, item.ExOrder.CreatTime.length - 8) }}</div>
								<div>{{ item.ExOrder.CreatTime.substring(10, item.ExOrder.CreatTime.length) }}</div>
							</div>
						</td>
						<td class="border-right">
							<div class="red">
								<span v-if="item.ExOrder.ExType == 1">退货退款</span>
								<span v-else>仅退款</span>
							</div>
						</td>
						<td class="border-right">
							<div class="reds">
								<span v-if="item.ExOrder.State == 0 || item.ExOrder.State == 10">审核中</span>
								<span v-else-if="item.ExOrder.State == 1">审核通过</span>
								<span v-else-if="item.ExOrder.State == 2">审核失败</span>
								<span v-else-if="item.ExOrder.State == 3">买家已发货</span>
								<span v-else-if="item.ExOrder.State == 4">商家确认中</span>
								<span v-else-if="item.ExOrder.State == 5">退款成功</span>
								<span v-else-if="item.ExOrder.State == 6">商家确认中</span>
								<span v-else-if="item.ExOrder.State == 7">商家确认中</span>
								<span v-else-if="item.ExOrder.State == 8">商家确认中</span>
								<span v-else-if="item.ExOrder.State == 9">已取消</span>
							</div>
						</td>
						<td class="border-right">
							<div>
								<div>
									<div v-if="item.ExOrder.State == 1" class="handle" @click="toLogistics(j, item)">填写物流信息</div>
									<div class="view" @click="view(j)">查看详情</div>
								</div>
								<div v-if="item.ExOrder.State == 0 || item.ExOrder.State == 1 || item.ExOrder.State == 2" class="cancel" @click="cancel(j)">取消申请</div>
							</div>
						</td>
					</tr>
				</table>
			</div>
		</div>
		<div class="pagination">
			<div>
				<el-pagination
					background
					@size-change="changeSize"
					@current-change="changeCurrent"
					:page-sizes="[10, 50, 100, 200]"
					:page-size="20"
					layout="total, sizes, prev, pager, next, jumper"
					:total="count"
				></el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
import { getExOrderList } from './service';
import { cancleExOrder } from '../logistics/service';

export default {
	data() {
		return {
			count: 0,
			pic: FILE_URL,
			cartList: [],
			parameter: {
				PageIndex: 1,
				PageSize: 10
			},
			form: {}
		};
	},
	mounted() {
		this.init();
	},
	methods: {
		toChat(item) {
			window.open('https://mcs.hexagonmetrology.cn/im/text/0hnzjp.html')
			// let ExOrder = item.ExOrder;
			// this.ysf('product', {
			// 	show: 1, // 1为打开， 其他参数为隐藏（包括非零元素）
			// 	title: '退款编号：' + ExOrder.ExOrderID,
			// 	desc: '退款商品：' +  item.DetailInfoList[0].ExOrderDetail.ProName + ' / 退款件数：' + item.DetailInfoList[0].ExOrderDetail.PCount + '件 / 退款金额：'+item.DetailInfoList[0].ExOrderDetail.PriceTotal+' / 退款方式：原支付方式退回 / 货物状态：' + (ExOrder.TrackType == 1 ? '未收到货' : '已收到货'),
			// 	picture: FILE_URL +  item.DetailInfoList[0].ExOrderDetail.ProPictrue,
			// 	note: ' 退款原因：' + ExOrder.Remark ? ExOrder.Remark : '无',
			// 	success: () => {
			// 		// 成功回调
			// 		this.ysf('open');
			// 	},
			// 	error: () => {
			// 		// 错误回调
			// 		this.ysf('open');
			// 	}
			// });
		},
		handleTo(data) {
			let u = localStorage.getItem('u');
			window.open(`/commodity?pid=${data.OrderDetail.PID}&u=${u}`, '_blank').location;
		},
		view(j) {
			this.$router.push({
				path: `/me/goodsDetails?OrderID=${j.ExOrderDetail.ExOrderID}`
			});
		},
		search() {
			this.parameter.PageIndex = 1;
			this.parameter.PageSize = 10;
			this.init();
		},
		changeSize(size) {
			this.parameter.PageSize = size;
			this.init();
		},
		cancel(j) {
			let data = {
				ExOrderID: j.ExOrderDetail.ExOrderID
			};
			cancleExOrder(data).then(() => {
				this.$message.success('提交成功');
				this.init();
			});
		},
		changeCurrent(size) {
			this.parameter.PageIndex = size;
			this.init();
		},
		init() {
			getExOrderList(this.parameter).then(res => {
				this.count = res.count;
				this.cartList = res.data;
			});
		},
		toLogistics(j, item) {
			let i = j.OrderDetail;
			j = j.ExOrderDetail;
			this.$router.push({
				path: `/me/logistics?CreatTime=${item.ExOrder.CreatTime}&OrderID=${j.ExOrderID}&ProName=${j.ProName}&ProUnitPrice=${j.ExPrice}&PCount=${j.PCount}&DisPrice=${
					i.DisPrice
				}&LastPrice=${i.LastPrice}&ProPictrue=${j.ProPictrue}&BarCode=${j.BarCode}&TaxPrice=${i.TaxPrice}&PID=${j.PID}`
			});
		}
	}
};
</script>

<style lang="less" scoped>
.handle {
	cursor: pointer;
}
.pagination {
	display: flex;
	margin-top: 30px;
	justify-content: flex-end;
	margin-right: 30px;
}
.top {
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid #dcdcdc;
	padding-bottom: 5px;

	.title {
		font-size: 18px;
		font-family: 'ct';
	}

	.policy {
		font-size: 12px;
		color: #0097ba;
		margin-top: 10px;
	}
}
.view {
	cursor: pointer;
}
.sku {
	width: fit-content;
	padding: 2px 10px;
	background: #f5f5f5;
	border: 1px solid #dedede;
	border-radius: 15px;
	color: #333333;
	font-size: 12px;
	margin: 10px 0px;
}
.form {
	span {
		cursor: pointer;
	}
}
.form {
	margin-top: 20px;
}
.cancel {
	cursor: pointer;
}
.list {
	margin-top: 10px;

	.list-title {
		background: #eeeeee;
		height: 40px;
		text-align: center;
	}

	.order-mian {
		width: 1300px;
		height: 30px;
		line-height: 30px;
		font-size: 12px;
		margin: 10px 0;
		background: #f9f9f9;
		padding-left: 10px;
		box-sizing: border-box;
	}

	.order-mian-list {
		text-align: center;
		font-size: 14px;
		margin: 10px 0;
	}

	.order-mian-list-text {
		text-align: left;
		margin: 10px;
	}

	.subtotal {
		font-family: 'ct';
		color: #1b98aa;
		font-size: 20px;
	}

	.button {
		width: fit-content;
		padding: 2px 10px;
		margin: 10px auto;
		background: rgba(27, 152, 170, 0);
		border: 1px solid #d9d9d9;
		color: #999999;
	}

	img {
		width: 100px;
		height: 100px;
	}

	.border {
		padding-bottom: 10px;
		border-bottom: 1px solid #f4f4f4;
	}

	.border-left {
		border-left: 1px solid #f4f4f4;
	}

	.border-right {
		border-right: 1px solid #f4f4f4;
		border-bottom: 1px solid #f4f4f4;
	}

	.order-details {
		margin-top: 30px;
	}

	.paidui {
		color: #999999;

		span {
			margin-left: 5px;
		}
	}

	.handle {
		width: fit-content;
		margin: 10px auto;
		padding: 5px 15px;
		color: #ffffff;
		background: #1b98aa;
	}

	.view {
		width: fit-content;
		margin: 10px auto;
		padding: 5px 15px;
		color: #333333;
		background: rgba(238, 238, 238, 0);
		border: 1px solid #dcdcdc;
	}

	.red {
		color: #dc2310;
	}
}
.proName {
	width: 200px;
	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	line-clamp: 2;
	-webkit-box-orient: vertical;
	font-size: 14px;
}
.reds {
	color: #1b98aa;
}
</style>
